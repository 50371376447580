import tyreb from "../../assets/tyreb.png";
import tyrea from "../../assets/tyrea.png";
const tyreData = {
  sidewall_bulge: {
    data: {
      cause: "Tyre sidewell pinched between road and wheel",
      recommendation: "Replace Tyre",
    },
    image: "https://i.imgur.com/Bq2w7Px.png",
  },
  sidewall_cut: {
    data: {
      cause: "Tyre got hit by some sharp and hard external object",
      recommendation: "Replace Tyre",
    },
    image: "https://i.imgur.com/Zb0ZBtp.png",
  },
  good: {
    data: "No Defects Observed",
    image: "https://i.imgur.com/dhM2KTP.png",
  },
};

export default tyreData;
