import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Image } from "@chakra-ui/core";
import { Card } from "../custom/Card";
import "./result.css";
import tyreData from "../../data/tyreData";

const Results = (props) => {
  console.log(props.results);

  return (
    <div
      style={{
        backgroundColor: "#000",
        minHeight: "100%",
        paddingBottom: "1em",
      }}
    >
      <Image src={tyreData[props.results[0].intent].image} size="100%" />
      <Box
        color="#4e4e4e"
        alignSelf="center"
        justifyContent="center"
        display="flex"
        padding="1em"
        className="heading"
        fontSize="2rem"
      >
        Scan results
      </Box>
      <Box d="flex" flexDirection="column" alignItems="center" p="1em">
        {props.results.map((result, i) => {
          return (
            <Card
              data={result.intent}
              key={result.intent.toString()}
              isVoice={false}
            />
          );
        })}{" "}
      </Box>
    </div>
  );
};

export default withRouter(Results);
