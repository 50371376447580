import { Box, Divider, Image } from "@chakra-ui/core";
import skodaLogo from "../../../assets/skodaLogo.png";
import React from "react";

const skodaHeader = () => {
  return (
    <Box style={{ display: "flex" }}>
      <Box boxSize="sm">
        <Image
          src="https://i.imgur.com/rgK2zj0.png"
          alt="Dan Abramov"
          style={{
            height: "70px",
            width: "auto",
            maxWidth: "unset",
          }}
        />
      </Box>
      <Box boxSize="sm">
        <Image
          src="https://imgur.com/aiKrFKH.png"
          alt="Dan Abramov"
          style={{ height: "60px" }}
        />
      </Box>
    </Box>
  );
};

export default skodaHeader;
