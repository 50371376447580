import React from "react";
import "./styles.css";
import { Box, Image, Text } from "@chakra-ui/core";
import { FaCamera, FaMicrophone, FaRocketchat } from "react-icons/fa";
import SkodaHeader from "../../components/Header/SkodaHeader";
import { withRouter } from "react-router-dom";

const Home = (props) => {
  return (
    <div className="main">
      <SkodaHeader />
      {/* <div className="heading-container"> */}
      <Text fontSize="xxl" className="heading">
        Tyre Defect Identification
      </Text>
      {/* </div> */}
      <div
        className="camera"
        onClick={() => {
          props.history.push("/camera");
        }}
      >
        <Box as={FaCamera} size="36px" color="#4e4e4e" />
      </div>

      {/* <div className="mic"  onClick={()=>{props.history.push('/voice')}}>
        <Box as={FaMicrophone} size="36px" color="#4e4e4e" />
      </div> */}
      {/* <div
        className="mic"
        
      >
        <Box as={FaMicrophone} size="36px" color="#4e4e4e" opacity={0.3}/>
      </div> */}
      {/* <div
        className="text"
        onClick={() => {
          props.history.push("/text");
        }}
      >
        <Box as={FaRocketchat} size="36px" color="#4e4e4e" />
      </div> */}
      <Box className="xane_icon">
        <Image src={require("./xane.png")} alt="Segun Adebayo" size="96px" />
      </Box>
    </div>
  );
};

export default withRouter(Home);
