import React, { Component } from "react";
import Chat from "../../components/Chat/Chat";
import axios from "axios";
export default class ChatContainer extends Component {
  state = {
    message: "",
    results: [],
    loading: false,
    query: "",
  };

  // componentDidMount() {
  //   axios
  //     .post(
  //       "https://xanegin.xane.ai/query",
  //       {
  //         text: "ignition switch",
  //         variant_code: "ER4CD1",
  //       },
  //       {
  //         headers: {
  //           "x-key":
  //             "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       // let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
  //       //let filteredResponses = response.data.data.predictions.splice(0,3);
  //       this.setState({
  //         results: response.data.predictions,
  //         loading: false,
  //       });
  //     });
  //   // axios
  //   //   .post(
  //   //     "https://xanegin.xane.ai/query",
  //   //     {
  //   //       image_url: this.props.location.state.img,
  //   //       variant_code: "abc",
  //   //     },
  //   //     {
  //   //       headers: {
  //   //         "x-key":
  //   //           "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
  //   //       },
  //   //     }
  //   //   )
  //   //   .then((response) => {
  //   //     console.log(response.data.data.predictions);
  //   //     let arr = [];
  //   //     let obj = {};
  //   //     obj.type_of_tyre = "Car";
  //   //     obj.tyre_id = parseInt(Math.floor(Math.random() * 1000));
  //   //     obj.detect_identified =
  //   //       response.data.data.predictions[0].intent == "normal" ? "No" : "Yes";
  //   //     obj.detect_classification = response.data.data.predictions[0].intent;
  //   //     obj.confidence = response.data.data.predictions[0].confidence;
  //   //     obj.inspection_summary =
  //   //       response.data.data.predictions[0].intent == "normal"
  //   //         ? "Accepted"
  //   //         : response.data.data.predictions[0].intent == "sidewall"
  //   //         ? "Rejected"
  //   //         : "To be Repaired";
  //   //     arr = [...arr,obj];

  //   //     this.setState({ results: arr, loading: false });
  //   //   });
  // }

  fetchResponse = (msg) => {
    let query = msg;
    axios
      .post(
        "https://xanegin.xane.ai/query",
        {
          text: msg,
          project: "msil-assist",
          limit: 3,
        },
        {
          headers: {
            "x-key":
              "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
          },
        }
      )
      .then((response) => {
        //console.log(response.data);
        // let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
        //let filteredResponses = response.data.data.predictions.splice(0,3);
        this.setState({
          results: response.data.data.predictions,
          loading: false,
          query: query,
          message: "",
        });
      });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.fetchResponse(this.state.message);
    }
  };

  inputChange = (e) => {
    this.setState({ message: e.target.value });
  };

  render() {
    return (
      <div>
        <Chat
          theme={this.props.theme}
          onChange={this.inputChange}
          message={this.state.message}
          loading={this.state.loading}
          results={this.state.results}
          fetchResponse={this.fetchResponse}
          _handleKeyDown={this._handleKeyDown}
          query={this.state.query}
        />
      </div>
    );
  }
}
