import React, { Component } from "react";
import { Voice } from "../../components/Voice/Voice";
import axios from "axios";

class VoiceContainer extends Component {
  state = {
    reply: "",
  };
  fetchResponse = async (msg) => {
    let response = await axios.post(
      "https://xanegin.xane.ai/query",
      {
        text: msg,
        project: "msil-assist",
      },
      {
        headers: {
          "x-key":
            "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
        },
      }
    );
    return response.data.data.predictions[0].intent;
    
  };

  render() {
    return (
      <Voice fetchResponse={this.fetchResponse} reply={this.state.reply} />
    );
  }
}

export default VoiceContainer;
