import React, { Component } from "react";
import Results from "../../components/Results/Results";
import { withRouter } from "react-router-dom";
import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Box, Spinner, Text } from "@chakra-ui/core";
import TyreLoader from "../../components/custom/Loader/TyreLoader";
class ResultsContainer extends Component {
  state = {
    loading: true,
    isLoadingPhases: true,
    loadingText: "",
    results: [],
  };

  componentDidMount() {
    const s3 = new AWS.S3({
      accessKeyId: "AKIA5SFEKY3CPFYTDLZV",
      secretAccessKey: "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",
    });
    this.setState({
      loadingText: "Analysing Picture",
    });

    let folder = `unsorted`;
    console.log(folder);
    let fileName = `${uuidv4().replace(/\-/g, "")}.jpg`;
    // let readFileOptions = { encoding: 'utf8' }
    // const fileContent = (params.files.image.data)
    var Location = null;
    let buf = new Buffer.from(
      this.props.location.state.img.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let params = {
      Bucket: `xane-user-data`,

      Key: `${folder}/${fileName}`, // File name you want to save as in S3
      Body: buf,
      ACL: "public-read",
      ContentType: `image/jpeg`,
      ContentEncoding: "base64",
    };
    s3.upload(params, async (err, data) => {
      if (data) {
        this.setState({
          loadingText: "Scanning for Defects",
        });
      }
      if (err) {
        throw err;
      }

      // const location = data.Location.replace(
      // 	'public-216685040946.s3-accesspoint.ap-south-1.amazonaws.com',
      // 	'public.s3.ap-south-1.amazonaws.com'
      // ).replace('accesspoint-', '');
      Location = data.Location;
      console.log(data.Location);
      // this.setState({ image: Location }, () => {
      // 	this.getResults(this.state.image);
      // });
      //this.setState({ inputValue: Location });

      axios
        .post(
          // 'https://api.assist.marutisuzuki.com/dev/interactions',
          "https://xanegin.xane.ai/query",
          {
            // image: this.props.location.state.img.split(",")[1],
            // image: data.Location,
            // variant_code: 'SSR4AL1',
            image_url: data.Location,
            project: "internal",
            delay: 3000,
            limit: 3,
          },
          {
            headers: {
              "x-key":
                "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
              // 'x-key': 'eecc2f7ce69040d9844d52bdb46cf167bdc878e8292d40aaaadea1f37db4e465',
              // 'x-platform': 1,
              // 'x-device-id': 'device',
              // 'x-origin': 1,
              // 'x-version': 1,
            },
          }
        )
        .then((response) => {
          // let filteredResponses = response.data.data.predictions[0].intent.replace(/_/g, ' ').toUpperCase();
          let filteredResponses = response.data.data.predictions.splice(0, 3);
          this.setState({
            loadingText: "Fetching Results",
          });
          setTimeout(
            function () {
              this.setState({
                results: filteredResponses,
                loading: false,
              });
            }.bind(this),
            2000
          );
        });
    });
    // axios
    //   .post(
    //     "https://xanegin.xane.ai/query",
    //     {
    //       image_url: this.props.location.state.img,
    //       variant_code: "abc",
    //     },
    //     {
    //       headers: {
    //         "x-key":
    //           "a688d8cdbc0a4ebebf5f24c545ceb3693942720f2fb84d84b35d1834b7b7eb66",
    //       },
    //     }
    //   )
    //   .then((response) => {
    //     console.log(response.data.data.predictions);
    //     let arr = [];
    //     let obj = {};
    //     obj.type_of_tyre = "Car";
    //     obj.tyre_id = parseInt(Math.floor(Math.random() * 1000));
    //     obj.detect_identified =
    //       response.data.data.predictions[0].intent == "normal" ? "No" : "Yes";
    //     obj.detect_classification = response.data.data.predictions[0].intent;
    //     obj.confidence = response.data.data.predictions[0].confidence;
    //     obj.inspection_summary =
    //       response.data.data.predictions[0].intent == "normal"
    //         ? "Accepted"
    //         : response.data.data.predictions[0].intent == "sidewall"
    //         ? "Rejected"
    //         : "To be Repaired";
    //     arr = [...arr,obj];

    //     this.setState({ results: arr, loading: false });
    //   });
  }

  render() {
    const { results } = this.state;
    return this.state.loading ? (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="#000"
          h="100vh"
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#4e4e4e"
          size="xl"
        /> */}
            <TyreLoader />
            <Text
              fontSize="xl"
              style={{
                color: "#4e4e4e",
                fontWeight: "600",
                marginTop: "25%",
                fontSize: "1.5rem",
              }}
            >
              {this.state.loadingText}...
            </Text>
          </Box>
        </Box>
        {/* <Text fontSize="sm">Tyre Defect Identification</Text> */}
      </>
    ) : (
      <div>
        <Results
          image={this.props.location.state.img}
          results={results ? results : []}
        />
      </div>
    );
  }
}

export default withRouter(ResultsContainer);
